import shopifyGenericDefaults from '../shopify-generic/config.js';

const genericUpdateCallback = shopifyGenericDefaults.Widgets.find(
  (w) => w.name === 'SearchResult',
).updateCallback;

const updateCallback = () => {
  genericUpdateCallback?.();
  globalThis.quickView();
  globalThis.ajaxCart.init();
};

shopifyGenericDefaults.Widgets.find((w) => w.name === 'Garage').location = {
  selector: '#cm-garage',
  class: 'block-link link-dropdown',
};

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  product: {
    ...shopifyGenericDefaults.product,
    searchResultsImageWidth: 500,
    searchResultsImageHeight: 500,
  },
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => w.name !== 'SearchResult'),
    {
      name: 'SearchResult',
      infiniteScrollDisabled: !!window.Convermax.config?.infiniteScrollDisabled,
      updateCallback,
    },
  ],
};
