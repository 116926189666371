
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'cm_product-item grid-item__link' }, _createElement('div', { 'className': 'product-item style-01' }, _createElement('div', { 'className': 'product-inner' }, _createElement('div', { 'className': 'product-thumb' }, _createElement('a', {
                    'className': 'thumb-link',
                    'href': this.url
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), !!this.image2 ? _createElement('div', {
                    'className': 'second-image',
                    'key': '385'
                }, _createElement('a', {
                    'href': this.url,
                    'className': 'thumb-link'
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2')
                }))) : null, _createElement('div', { 'className': 'flash' }), _createElement('div', { 'className': 'group-button' }, _createElement('a', {
                    'href': this.url,
                    'data-id': this.handle,
                    'className': 'engoj_btn_quickview button engoc-btn-quickview',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'title': this.removeHTML(this.title),
                    'data-original-title': 'Quickview'
                }, _createElement('i', { 'className': 'flaticon-magnifying-glass-browser fz-21' })), _createElement('div', { 'className': 'product compare-button' }, _createElement('a', {
                    'href': this.url,
                    'className': 'compare button',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'View now'
                }, _createElement('i', { 'className': 'flaticon-shuffle' }))))), _createElement('div', {
                    'className': 'product-info equal-elem',
                    'style': { height: '164px' }
                }, _createElement('div', { 'className': 'cat-list' }, _createElement('a', Object.assign({}, { 'href': this.vendor_url }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'product-name product_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('span', { 'className': 'price' }, this.compare_at_price !== this.price ? _createElement('del', { 'key': '1778' }, this.formatPrice(this.compare_at_price)) : null, _createElement('ins', {}, this.formatPrice(this.price))), _createElement('div', { 'className': 'add-to-cart' }, _createElement('div', { 'className': 'add-to-cart-wrap azirspares-add-to-cart-wrap' }, this.out_of_stock || this.variant_ids.length > 1 ? _createElement('a', {
                    'href': this.url,
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'key': '2060'
                }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' })) : null, !this.out_of_stock && this.variant_ids.length < 2 ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'key': '2271'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids[0]
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'id': 'AddToCart' + this.id,
                    'className': 'enj-add-to-cart-btn engoc-btn-default',
                    'data-toggle': 'tooltip',
                    'data-placement': 'top',
                    'data-original-title': 'Add to Cart'
                }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' }))) : null))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'cm_related-products-title-container' }, _createElement('h2', { 'className': 'cm_related-products-title' }, 'You might also like...')), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-products-list cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []