//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-632:_4024,_5808,_6428,_5188,_6028,_6140,_1816,_9444;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-632')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-632', "_4024,_5808,_6428,_5188,_6028,_6140,_1816,_9444");
        }
      }catch (ex) {
        console.error(ex);
      }